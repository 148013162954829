import { osiris } from '../api'

class RateService {
    api = osiris

    async index() {
      try {
        return await this.api.get('/rate/standard')
      } catch ({ response }) {
        return response
      }
    }

    async update(data) {
      try {
        return await this.api.put('/rate/standard', { data })
      } catch ({ response }) {
        return response
      }
    }

    async getByGroupId(id) {
      try {
        return await this.api.get(`/rate/group/${id}`)
      } catch ({ response }) {
        return response
      }
    }

    async updateByGroupId(request, id) {
      try {
        return await this.api.put(`/rate/group/${id}`, request)
      } catch ({ response }) {
        return response
      }
    }

    async extract() {
      try {
        return await this.api.get('/extract/user')
      } catch ({ response }) {
        return response
      }
    }

    async extractByGroupId(groupId) {
      try {
        return await this.api.get(`/extract/group/${groupId}`)
      } catch ({ response }) {
        return response
      }
    }

    async extractByGroupIdAndMonth(group, month) {
      try {
        return await this.api.get(`/extract/group/${group}/month/${month}`)
      } catch ({ response }) {
        return response
      }
    }
}

export default new RateService()
